<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<p-toast />
<div class="fixed w-full z-50 bg-blue-light">
  <lfx-header-v2 id="lfx-header" product="LF DA" userefreshtoken="true"></lfx-header-v2>
</div>
<div id="main-content" class="pt-[60px]">
  <div id="app">
    <div class="app-body">
      <lfx-side-nav
        [ngClass]="{ hidden: isLandingPage() }"
        [isFoundation]="isFoundationPage()"
        (toggleCollapsed)="toggleCollapsed()"
        (collapsed)="collapsed()"></lfx-side-nav>
      <div
        id="router-container"
        [ngClass]="{ 'ml-[225px]': !isCollapsed() && !isLandingPage(), 'ml-[70px]': isCollapsed() || isLandingPage() }">
        <div>
          <router-outlet></router-outlet>
        </div>
        <div class="flex justify-around items-center mt-auto">
          <lfx-footer></lfx-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loggedOut>
  <lfx-header-v2 id="lfx-header" product="LF DA"></lfx-header-v2>
</ng-template>
